import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";

import {
    FaHome,
    FaInstagram,
    FaPizzaSlice,
    FaUtensils,
    FaIceCream,
    FaSearch,
} from "react-icons/fa";

// Styles
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node;

    return (
        <>
            <Helmet>
                <meta
                    name="p:domain_verify"
                    content="c2031ef97ca13628861f683888103bac"
                />

                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>
            <CookieConsent
                location="bottom"
                buttonText="Amaga"
                cookieName="tombatruites-cookies-gdpr"
                style={{ background: "#2c1b3e", textAlign: "center" }}
                buttonWrapperClasses="buttonCookieWrapper"
                buttonStyle={{
                    backgroundColor: "#d4cede",
                    color: "#2c1b3e",
                    fontSize: "15px",
                    borderRadius: "0.6rem",
                }}
                expires={150}
            >
                Aquest lloc web, a part de receptes de galetes, també fa servir
                cookies. Si continues navegant estàs donant el seu consentiment.
            </CookieConsent>
            <div className="viewport">
                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header className="site-head">
                        <div className="container">
                            <div className="site-mast">
                                <Link to="/">
                                    <img
                                        className="site-logo"
                                        src="/images/logo.svg"
                                        alt={site.title}
                                    />
                                </Link>
                            </div>
                            <div className="site-mast">
                                <h2 className="site-title">
                                    receptes de cuina en català
                                </h2>
                            </div>
                        </div>

                        <div className="container">
                            <nav className="site-nav">
                                <a
                                    aria-current="page"
                                    className="site-nav-item"
                                    href="/"
                                >
                                    <FaHome
                                        className="site-nav-item-icon"
                                        alt="Inici"
                                    />
                                    Inici
                                </a>

                                <a
                                    aria-current="page"
                                    className="site-nav-item"
                                    href="/tag/pa-pasta-i-pizza/"
                                >
                                    <FaPizzaSlice
                                        className="site-nav-item-icon"
                                        alt="Pa, pasta i pizza"
                                    />
                                    Pa, pasta i pizza
                                </a>

                                <a
                                    aria-current="page"
                                    className="site-nav-item"
                                    href="/tag/salat/"
                                >
                                    <FaUtensils
                                        className="site-nav-item-icon"
                                        alt="Salat"
                                    />
                                    Salat
                                </a>

                                <a
                                    aria-current="page"
                                    className="site-nav-item"
                                    href="/tag/dolcos/"
                                >
                                    <FaIceCream
                                        className="site-nav-item-icon"
                                        alt="Dolços"
                                    />
                                    Dolços
                                </a>

                                <a
                                    aria-current="page"
                                    className="site-nav-item"
                                    href="https://www.instagram.com/tombatruites"
                                >
                                    <FaInstagram
                                        className="site-nav-item-icon"
                                        alt="Instagram"
                                    />
                                    Instagram
                                </a>
                            </nav>
                        </div>
                    </header>

                    <main className="site-main">
                        {isHome ? (
                            <div>
                                <div className="site-mast">
                                    <h2 className="searchRecipes">
                                        BUSCA RECEPTES:
                                    </h2>
                                </div>
                                <div className="site-mast">
                                    <form
                                        action="https://www.google.com/search"
                                        className="search-form"
                                        method="get"
                                        name="searchform"
                                        target="_blank"
                                    >
                                        <input
                                            name="sitesearch"
                                            type="hidden"
                                            value="tombatruites.cat"
                                        />
                                        <input
                                            autoComplete="on"
                                            className="form-control search"
                                            name="q"
                                            placeholder="pizza de gorgonzola"
                                            required="required"
                                            type="text"
                                        />
                                        <button
                                            className="search-button"
                                            type="submit"
                                        >
                                            <FaSearch alt="Search" />
                                        </button>
                                    </form>
                                </div>
                                <div className="site-mast">
                                    <h2 className="lastRecipes">
                                        ÚLTIMES RECEPTES:
                                    </h2>
                                </div>
                            </div>
                        ) : null}

                        {/* All the main content gets inserted here, index.js, post.js */}
                    </main>
                </div>

                <div className="loop">{children}</div>

                <div className="site-mast" />

                {!isHome ? (
                    <div style={{ marginBottom: 20 + `px` }}>
                        <hr
                            style={{
                                borderTop: "1px dotted #2c1b3e",
                                opacity: "10%",
                                width: "60%",
                                marginBottom: 40 + `px`,
                            }}
                        />

                        <div className="site-mast">
                            <h2 className="searchRecipes">BUSCA RECEPTES:</h2>
                        </div>
                        <div className="site-mast">
                            <form
                                action="https://www.google.com/search"
                                className="search-form"
                                method="get"
                                name="searchform"
                                target="_blank"
                            >
                                <input
                                    name="sitesearch"
                                    type="hidden"
                                    value="tombatruites.cat"
                                />
                                <input
                                    autoComplete="on"
                                    className="form-control search"
                                    name="q"
                                    placeholder="pizza de gorgonzola"
                                    required="required"
                                    type="text"
                                />
                                <button className="search-button" type="submit">
                                    <FaSearch alt="Search" />
                                </button>
                            </form>
                        </div>
                    </div>
                ) : null}

                <div
                    className="site-main container"
                    style={{ paddingTop: 50 + `px` }}
                >
                    <div className="site-mast">
                        <h2 className="searchRecipes">
                            RECEPTES PER ETIQUETA:
                        </h2>
                    </div>

                    <div className="tag-list">
                        {data.allGhostTag.edges.map((item) => {
                            return (
                                <Link
                                    key={item.node.slug}
                                    className="tag-list-item"
                                    to={`/tag/` + item.node.slug}
                                >
                                    {`# ` + item.node.name}
                                </Link>
                            );
                        })}
                    </div>
                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav">
                            <div>© 2020 Marta Albiol Tendillo</div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
        allGhostTag: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                allGhostTag {
                    edges {
                        node {
                            ...GhostTagFields
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
