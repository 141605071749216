import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`;

    return (
        <div className="post-item-wrapper">
            <div className="post-item-data">
                <h2 className="post-title">
                    <a href={url} title={post.title}>
                        {post.title}
                    </a>
                </h2>
                <div className="post-meta">
                    <p
                        style={{ marginTop: 20 + "px" }}
                        dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                </div>
                <div className="tag-list-post">
                    {post.tags.map(tag => {
                        return (
                            <Link
                                key={tag.slug}
                                className="tag-list-post-item"
                                to={`/tag/` + tag.slug}
                            >
                                {`# ` + tag.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className="post-item-image-parent">
                <div
                    className="post-item-image-child"
                    style={{
                        backgroundImage: `url(${post.feature_image})`
                    }}
                >
                    <a href={url} title={post.title}>
                        <div className="fullDivLink"></div>
                    </a>
                </div>
            </div>
        </div>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string
            })
        ),
        excerpt: PropTypes.string.isRequired
    }).isRequired
};

export default PostCard;
